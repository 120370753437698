import * as React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { StaticImage } from "gatsby-plugin-image"
import ProductCategory from "../../components/ProductCategory/ProductCategory"
import PageLinks from "../../components/PageLinks/PageLinks"

const machining = () => {
  return (
    <>
      <Seo title="머시닝센터" />
      <Layout>
        <SimpleBanner subtitle="절삭가공" title="머시닝센터">
          <StaticImage
            className="banner__image"
            src="../../images/feeler/lathesCover.png"
            layout="constrained"
            placeholder="blurred"
          />
        </SimpleBanner>
        <ProductCategory productCategory="machining" />
        <PageLinks brand="feeler" />
      </Layout>
    </>
  )
}

export default machining
